.border-radius-22-px {
  border-radius: 22px;
}
.border-radius-10-px {
  border-radius: 10px;
}
.border-placeholder {
  border-color: $placeholder-color !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-color-lime {
  border-color: #43ad00 !important;
}
