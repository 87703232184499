.toggle-container {
  .tcheader {
    cursor: pointer;
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: 1rem;
  }

  &.lg .tcheader {
    font-size: 21px;
  }

  .icon {
    @include transition(transform 0.3s ease-out);
  }

  &.link .icon {
    transform: rotate(-90deg);
  }

  &.show {
    .icon {
      transform: rotate(180deg);
    }
  }
}
