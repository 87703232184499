/* text utilities */

.text-white-80 {
  color: rgba($white, 0.8);
}

.text-black-40 {
  color: rgba($black, 0.4);
}

.text-black-60 {
  color: rgba($black, 0.6);
}

.text-black-80 {
  color: rgba($black, 0.8);
}

.text-black-90 {
  color: rgba($black, 0.9);
}

.text-white-90 {
  color: rgba($white, 0.9);
}

.text-white-60 {
  color: rgba($white, 0.6);
}
.text-white-50 {
  color: rgba($white, 0.5);
}

.text-black-20 {
  color: rgba($black, 0.2) !important;
}
.font-weight-500 {
  font-weight: 500;
}
