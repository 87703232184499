.eq-accordion {
  margin-bottom: 2rem;

  .card {
    display: flex;
    flex-wrap: wrap;
  }

  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    order: 1;
    font-weight: 700;
    border: 0;

    svg {
      @include transition(transform 0.3s ease-out);
    }
  }

  .accordion-body {
    order: 2;

    &.show + .accordion-header svg {
      transform: rotate(180deg);
    }
  }

  &.visible-card > .card {
    overflow: visible;
  }
}
