.w-65 {
  width: 65% !important;
}

.max-h-60 {
  max-height: 60vh;
}
.min-h-70 {
  min-height: 70vh;
}
.min-h-100 {
  min-height: 100vh;
}

.mw-50 {
  max-width: 50%;
}
.mw-100 {
  max-width: 100%;
}

.mw-100-px {
  max-width: calc(100px);
}

.mw-50-px {
  max-width: calc(50px);
}

.mw-90-px {
  max-width: calc(90px);
}

.mw-130-px {
  max-width: calc(130px);
}

.mw-360-px {
  max-width: calc(360px);
}

.mw-1200-px {
  max-width: calc(1200px);
}

.min-w-50 {
  min-width: 50%;
}

.h-280-px {
  height: calc(280px);
}

.h-100-vh {
  height: 100vh;
}

.w-70-px {
  width: 70px;
}
.h-70-px {
  height: 70px;
}

.w-200-px {
  width: calc(200px);
}

.w-280-px {
  width: calc(280px);
}

.mt-md-180-px {
  @include media-breakpoint-up(md) {
    margin-top: calc(180px) !important;
  }
}

.mx-neg-3 {
  margin-right: calc(-15px);
  margin-left: calc(-15px);
}

.w-lg-50 {
  @include media-breakpoint-up(lg) {
    width: 50% !important;
  }
}

.w-xl-350-px {
  @include media-breakpoint-up(xl) {
    width: calc(350px);
  }
}

.h-xl-350-px {
  @include media-breakpoint-up(xl) {
    height: calc(350px);
  }
}
