$primary: #2600aa;
$secondary: #f1f5f9;
$warning: #ffa737;
$lime: #43ad00;
$deactivated: #ff0808;

$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;

$font-family-base: $font-family-sans-serif;

$font-size-base: 0.875rem;

$enable-caret: false;

$tooltip-bg: $primary;

$dropdown-link-active-bg: #f1f5f9;

$theme-colors: (
  "lime": $lime,
  "deactivated": $deactivated
);

$sizes: (
  85: 85%
);

$btn-border-width: 1px;
