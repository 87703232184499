.modal-dialog {
  &.side-modal {
    margin: 0;
    width: 100%;
    max-width: 100%;

    @include media-breakpoint-up(md) {
      width: 80%;
      max-width: 1200px;
    }
    @include media-breakpoint-up(lg) {
      width: 70%;
    }

    .modal-content {
      border-width: 0 0 0 1px;
      border-radius: 0;
      min-height: 100vh;
    }
    .modal-body,
    .modal-header {
      border-radius: 0;
    }

    .modal.fade & {
      transform: translate3d(-25%, 0, 0);
    }
    .modal.show & {
      transform: none;
    }

    &.right {
      margin: 0 0 0 auto;

      .modal.fade & {
        transform: translate3d(25%, 0, 0);
      }
      .modal.show & {
        transform: none;
      }
    }
  }
}
