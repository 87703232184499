$checkbox-width: 1.2em;

input[type="checkbox"],
input[type="radio"] {
  appearance: none;
  border: 2px solid $input-color;
  width: $checkbox-width;
  height: $checkbox-width;
  vertical-align: middle;
  outline: none;
  transition: 0.125s box-shadow;

  &:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 173, 239, 0.25);
  }
}

input[type="checkbox"] {
  border-radius: 0.1em;
  &.round {
    border-radius: $checkbox-width/2;
  }
  &:checked {
    background-color: $primary;
    border: 0;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    background-size: 50% 50%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  &.primary:checked {
    background-color: $primary;
  }
}

$radio-check-width: 0.5em;

input[type="radio"] {
  border-radius: $checkbox-width/2;
  background: none;
  position: relative;
  margin-top: -1px;
  &:checked:after {
    content: " ";
    display: block;
    width: $radio-check-width;
    height: $radio-check-width;
    background: $black-60;
    border-radius: $radio-check-width/2;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -($radio-check-width/2);
    margin-top: -($radio-check-width/2);
  }
  &.primary:checked {
    border-color: $primary;
  }
  &.primary:checked:after {
    background: $primary;
  }
}
