.overflow-y-auto {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.opacity-1 {
  opacity: 1 !important;
}

.zindex-10 {
  z-index: 10;
}

.object-fit-cover {
  object-fit: cover;
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bg-disabled {
  background: $input-disabled-bg !important;
}

.cursor-pointer {
  cursor: pointer;
}
