.eq-tabs {
  border: 1px solid #000;
  border-width: 1px 0;

  &.nav-tabs {
    .nav-link {
      border: 0;
      color: $black-80;
      padding: 0.4rem 1rem;
      font-weight: 700;
      text-decoration: underline;
      &.active {
        color: $primary;
      }
    }
    .nav-item {
      margin: 0;
    }
  }
}
