/* buttons */

.btn-outline-danger {
  border-color: $danger;

  &:hover {
    color: #fff !important;
    background-color: $danger;
    border-color: $danger;
  }
}

.btn-outline-secondary-darker {
  border-color: #979797 !important;
}

// .btn-outline-secondary {
//   @extend .text-black-60;
//   @extend .font-size-12;
//   &:hover {
//     @extend .text-black-60;
//     background-color: #f5f5f5;
//   }
// }

.btn-outline-primary:hover {
  color: $white !important;
}

.btn-outline-primary:disabled {
  opacity: 0.3;
  background: $secondary;
  color: $input-color !important;
  border-color: $input-color;
}

.btn-md {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;

  &:hover {
    .arrow-right {
      path {
        fill: $white;
      }
    }
  }
}

.btn-only-xs-block {
  @include media-breakpoint-only(xs) {
    display: block;
    width: 100%;
  }
}

.btn-change-value .active {
  @extend .bg-success;
}

.btn-outline-success-light {
  border-color: #ced4da !important;

  &:not(.active) {
    color: rgba($black, 0.6);

    &:hover {
      color: $white;
    }
  }
}

// .add-image-btn {
//   height: calc(40px);
//   @extend .btn,
//     .btn-block,
//     .btn-outline-secondary,
//     .btn-outline-secondary-darker,
//     .d-flex,
//     .align-items-center,
//     .justify-content-center,
//     .text-black-60,
//     .font-size-13;
// }
