.warn-marker,
.required-marker {
  color: $warning;
  font-size: $input-font-size * 0.875;
}

.invalid-feedback {
  color: $warning;
}

.form-label {
  margin: 0;
  display: block;
  line-height: 1.5;
  font-weight: bold;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
}

.form-check label {
  margin-left: 0.25rem;
  line-height: 1.5rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background: #fff;
}

.table-checkbox .form-check-input {
  margin-top: 0;
}

input {
  &:not([type="checkbox"]):not([type="radio"]) {
    display: block;
    border: 0;
    line-height: 1.5;
    padding: space(1) 2px;
    border-bottom: 1px solid $border-color;
    border-radius: 0;
    &::placeholder {
      color: $placeholder-color;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &.has-error {
      border-color: $danger;
    }
    &.is-invalid {
      border-color: $warning;
    }
  }
}

textarea.form-control {
  border-color: $border-color;
}

// Can't simply add disabled style across, as a lot of fields,
// eg. search, merge company has disabled on them, it will look ugly.
.gray-disable-form input {
  &:not([type="checkbox"]):not([type="radio"]):disabled {
    color: $text-muted;
    background: $input-disabled-bg;
  }
}

.disabled-checkbox {
  input {
    border: 2px solid $text-muted !important;
  }
  label {
    color: $text-muted !important;
  }
  input[type="checkbox"]:checked {
    background-color: $text-muted;
  }
}

input,
label,
textarea {
  font-size: 14px;
}

select.form-control {
  border: 0;
  border-radius: 0px;

  &:not([multiple]):not([disabled]) {
    padding-left: 0;
  }

  &:focus {
    border-color: $primary;
    outline: none;
    box-shadow: none;
  }

  &.is-invalid {
    border-color: $warning;

    &:focus {
      border-color: $warning;
      box-shadow: none;
    }
  }
}

.react-select {
  .eq__control {
    box-shadow: none;
    border-color: $border-color;

    &.eq__control--is-focused {
      border-color: $border-color;
    }

    &.eq__control--is-disabled {
      background: $input-disabled-bg;
    }
  }
  &.is-invalid {
    .eq__control {
      border-color: $warning;

      &.eq__control--is-focused {
        border-color: $warning;
      }
    }
  }
  &.rounded .eq__control {
    border-radius: 22px;
  }
  .eq__placeholder {
    color: $placeholder-color;
  }
  .eq__indicator-separator {
    margin: 0;
  }
  .eq__indicator {
    color: $black-80;

    &:hover {
      color: $black-80;
    }
  }
}

.fileUploader.eq-img-upload {
  .fileContainer {
    background: transparent;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }
  .errorMessage {
    padding-bottom: 10px;
  }
  .chooseFileButton {
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 3px;
    background: transparent;
    font-weight: 500;
    border: 1px solid $primary;
    color: $primary;
    margin: 0;

    &:hover {
      background: $primary;
      color: #fff;
    }
  }
}
